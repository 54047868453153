import React from "react";
import '../../src/index.css'
import Stairs from './images/stairs.jpg'
import craft from  './images/bench.jpg'
import gold from './images/gold.jpg'
const Business=()=>{
    return(
        <section id="service">
        <h2>Business & Details:</h2>
        <h4>MHYN: Crafting Excellence Since 1935</h4>
        <p id="ser-sp">Discover the MHYN difference and elevate your surroundings with our unparalleled collection of timeless creations</p>
        <div className="content service--content" 
        data-aos="fade-up"
        data-aos-duration="3000"
        >
          <div className="item">
          
            <img src={Stairs} alt="Stairs design"></img>
            <br/>
            <p>At MHYN, we take pride in our heritage of creating exquisite cast iron and aluminum products that stand the test of time. From spiral stairs to garden benches, each piece is a work of art, meticulously crafted to blend aesthetics with functionality.</p>
            <a href="#contact">Contact</a>
          </div>
          <div className="item">
     
            <img src={craft}alt="Packaging design"></img>
            <br/>
            <p>Our business spans across India, Dubai, and Brazil, where we bring traditional craftsmanship to the global stage. With a focus on anti-corrosion technology, MHYN products adorn homes and spaces in the United Kingdom, France, Germany, Italy, USA, and beyond.</p>
            <a href="#contact">Contact</a>
          </div>
          <div className="item">
          
            <img src={gold} alt="3D image manipulation"></img>
            <br/>
            <p>Ebrahim Mhyn, the driving force behind our brand, brings a bold vision and unwavering commitment to quality. His ventures in gold design and brand imports further showcase MHYN's dedication to innovation and <br/>luxury.</p>
               <a href="#contact">Contact</a>
          </div>
        </div>
      </section>
    )
}
export default Business
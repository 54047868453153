import React from "react";
import facebook from '../assets/svg/facebook.svg'
import linkedin from '../assets/svg/linkedin.svg'
import twitter from '../assets/svg/twitter.svg'
import InstagramIcon from '@mui/icons-material/Instagram';
import '../../src/index.css'

const SocialMedia=()=>{
    return(
        <div className="social-share"
        data-aos="fade-up"
        data-aos-offset="200"
        data-aos-anchor-placement="center-top"
        >
              <a  href="https://www.instagram.com/iamebrahimmhyn?igsh=dmJlaG1pZWE2a2o0" target="_blank"><InstagramIcon id="ig"></InstagramIcon> </a>
          <a href="https://www.facebook.com/ayaan.mhyn.7" target="_blank"><img src={facebook} alt="share on facebook"></img></a>
          <a href="#" target="_blank"><img src={twitter} alt="share on twitter"></img></a>
   
          <a href="#" target="_blank"><img src={linkedin} alt="share on linkedin"></img></a>
       
        </div>
    )
}
export default SocialMedia


import React from "react";
import "../../src/index.css"
import vd from './images/vd.mp4'
const BannerPage=()=>{
    return(
    <section className="banner">
  <div className="banner-video">
  <video src={vd} control autoPlay muted loop className="background"></video>
  </div>
  <div className="banner-text"  data-aos="fade-in"
         data-aos-duration="2000">
    <h1>Welcome to MHYN - Where Tradition Meets Innovation</h1>
    <p> <br/><br/>Explore the exquisite world of MHYN, a legacy of craftsmanship dating back to 1935. Our family's heritage of creating exceptional cast iron and aluminum products has transcended borders, captivating hearts and homes globally
      Step into elegance with our signature spiral stairs, oriental railings, composite fences, balustrade stairs, and more - all meticulously designed for durability and anti-corrosion properties.
      Experience the journey of Ebrahim Mhyn, a visionary entrepreneur blending traditional Indian craftsmanship with a modern global perspective. Follow his adventures, from the vibrant streets of Dubai to the lush landscapes of Brazil, where MHYN continues to redefine luxury and style.
      Join us in celebrating a legacy of excellence, where every MHYN creation is a testament to timeless beauty and unmatched quality.</p>
  </div>
</section>          
    )
}
export default BannerPage
import React from "react";
import Logo from "./images/logo.png"
import '../../src/index.css'
import facebook from '../assets/svg/facebook.svg'
import linkedin from '../assets/svg/linkedin.svg'
import twitter from '../assets/svg/twitter.svg'
import InstagramIcon from '@mui/icons-material/Instagram';
const Footer = () => {
  return (
    <>
      <main className="main-section">
        <footer className="footer-sec">
          <div className="main">

            <div className="logo row">
              <div className="footer-header">
                <img src={Logo} className="abdullah" alt=""></img>
              </div>
              <div className="logo-des">
                <p>HAYAT IMPORTS AND SAGA LUXURY</p>

                <a href="#" className="btn-know">Know More</a>
              </div>

            </div>
            <div className="office row">
              <div className="footer-header">
                <h3>Office</h3>
              </div>
              <div className="office-des">
                <p>Mumbai, India</p>
                <a href="hamzamhyn@ymail.com">hamzamhyn@ymail.com</a>
                <p className="num">+5551995911777</p>
              </div>
            </div>

            <div className="link row">
              <div className="footer-header">
                <h3>Links</h3>
              </div>

              <div className="link-des">

                <a href="index.html">Home</a>
                <a href="#about">About </a>
                <a href="#service">Business & details</a>
                <a href="#portfolio">Gallery</a>
                <a href="#portfolio">Blog</a>
                <a href="#">contact</a>
              </div>

            </div>


            <div className="newsletter row">
              <div className="footer-header">
                <h3>Follow Us</h3>
              </div>
              <div className="newsletter-des">

                <div className="icons">
                  <a href="https://www.instagram.com/iamebrahimmhyn?igsh=dmJlaG1pZWE2a2o0" target="_blank" ><InstagramIcon id="ft-ig"></InstagramIcon> </a>
                  <a href="https://www.facebook.com/ayaan.mhyn.7" target="_blank"><img src={facebook} alt="share on facebook"></img></a>
                  <a href="#" target="_blank"><img src={twitter} alt="share on twitter"></img></a>

                  <a href="#" target="_blank"><img src={linkedin} alt="share on linkedin"></img></a>

                </div>
              </div>
            </div>


          </div>
          <div className="copyright">
            <hr />

            <p>&copy; <time datetime="2022">2024,</time> All rights reserved by HAYAT IMPORTS AND SAGA LUXURY</p>
          </div>
        </footer>
      </main>
    </>
  )
}
export default Footer
import React from "react";

import '../../src/index.css'
import G1 from './images/G1.jpeg'
import G2 from './images/G2.jpeg'
import G3 from './images/G3.jpeg'
import G4 from './images/G4.jpeg'
import G5 from './images/G5.jpeg'
import G6 from './images/G6.jpeg'
import G7 from './images/G7.jpeg'
import G8 from './images/G8.jpeg'
import G9 from './images/G9.jpeg'

 const Acheivements=()=>{
    return(
      <section id="glySec">
      <div className="glyContainer">
        <div className="glyHeader">
          <h1 className="glyLogo">Explore Ebrahim Mhyn's World</h1>
        
        </div>
        <div className="gallery">
          <div className="gltItem">
            <a href="#">
              <img id="glyImg"src={G1} alt="image"></img>
              {/* <h3>Idukki,Kerala</h3> */}
            </a>
          </div>
          <div className="gltItem">
            <a href="#">
            <img src={G2} alt="image"></img>
              {/* <h3>Vasai,Maharashtra</h3> */}
            </a>
          </div>
          <div className="gltItem">
            <a href="#">
            <img src={G3} alt="image"></img>
              {/* <h3>Vasai,Maharashtra</h3> */}
            </a>
          </div>
          <div className="gltItem">
            <a href="#">
            <img src={G4} alt="image"></img>
              {/* <h3>New Mumbai,Maharashtra</h3> */}
            </a>
          </div>
          <div className="gltItem">
            <a href="#">
            <img src={G5} alt="image"></img>
              {/* <h3>Thrissur,Kerala</h3> */}
            </a>
          </div>
          <div className="gltItem">
            <a href="#">
            <img src={G6} alt="image"></img>
              {/* <h3>Thrissur,Kerala</h3> */}
            </a>
          </div>
          <div className="gltItem">
            <a href="#">
            <img src={G7} alt="image"></img>
              {/* <h3>Thrissur,Kerala</h3> */}
            </a>
          </div>
          <div className="gltItem">
            <a href="#">
            <img src={G8} alt="image"></img>
              {/* <h3>Thrissur,Kerala</h3> */}
            </a>
          </div>
          <div className="gltItem">
            <a href="#">
            <img src={G9} alt="image"></img>
              {/* <h3>Thrissur,Kerala</h3> */}
            </a>
          </div>
        </div>
      </div>
    </section>
    )
 }
 export default Acheivements
import React from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../../src/index.css"
import { useEffect } from "react";
import Logo from './images/logo.png'
const Skill=()=>{
    useEffect(() => {
        AOS.init();
        AOS.refresh();
      }, []);
    return(
        <>
         <section id="skill">
    <div className="content skill--content">
      <div className="left">
        <h2>My skills &amp; expertise</h2>
        <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Ea quibusdam inventore molestias maiores esse eum, recusandae impedit? Neque inventore, saepe esse culpa, adipisci ipsum sed suscipit debitis in voluptatibus nisi.</p>
      </div>
      <div className="right">
        <div className="item"
        data-aos="flip-left"
        data-aos-duration="1000"
        >
          <img src={Logo} alt="Photoshop"></img>
          <h3>Abode Photoshop</h3>
          <p>laboriosam temporibus harum culpa recusandae nemo tempora ut vitae earum quo.</p>
        </div>
        <div className="item"
        data-aos="flip-right"
        data-aos-duration="2000"
        >
          <img src={Logo}alt="Illustrator"></img>
          <h3>Abode Illustrator</h3>
          <p>Sunt doloribus perferendis officia soluta enim optio distinctio exercitationem.</p>
        </div>
        <div className="item"
        data-aos="flip-left"
        data-aos-duration="1500"
        >
          <img src={Logo} alt="InDesign"></img>
          <h3>Abode InDesign</h3>
          <p>Lorem ipsum dolor sit amet consectetur adipisicing elit qui debitis.</p>
        </div>
      </div>
    </div>
    </section>
    </>
    )
}
export default Skill
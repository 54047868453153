import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Navbar from './components/navbar';
import BannerPage from './components/banner';
import About from './components/about';
import Skill from './components/skill';
import Business from './components/business';
import Acheivements from './components/acheivement';
import Testimonials from './components/testimonials';
import Pricing from './components/pricing';
import Contact from './components/contact';
import SocialMedia from './components/socialMedia';
import Footer from './components/footer';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    {/* <App /> */}
    <Navbar/>
    <BannerPage/>
    <About/>
    <Skill/>
    <Business/>
    <Acheivements/>
    {/* <Testimonials/> */}
    {/* <Pricing/> */}
    <Contact/>
    <SocialMedia/>
    <Footer/>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

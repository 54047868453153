import React from "react";
import Logo from "./images/logo.png"
import menu from './images/menu.png'
import AnchorLink from "react-anchor-link-smooth-scroll";
import About from "./about";
 const Navbar=()=>{
  
    return(
        <>
    <nav className="site-header">
    <div className="nav-logo">
      <a href="index.html">
        <img src={Logo} alt="logo" width="100" height="5"></img>
        <span> HAYAT </span>
      </a>
     
    </div>
 
 <button className="hamburger"><img src={menu} alt="menu toggle icon"></img></button>
    <div className="nav-links">
      <ul>
        <li><a href="index.html">Home</a></li>
        <li><a href="#about">About </a></li>
        <li><a href="#service">Business & details</a></li>
        <li><a href="#glySec">Gallery</a></li>
        <li><a href="#portfolio">Blog</a></li>
      

        <li><a href="#contact">Contact</a></li>
      </ul>
    </div>


  </nav></>
  )
}
export default Navbar


import React from "react";
import '../../src/index.css'
import EmailIcon from '@mui/icons-material/Email';
import CallIcon from '@mui/icons-material/Call';
const Contact=()=>{
   return(
    <section id="contact">
    <div className="content">
      <h2 className="center">Contact Me</h2>
      <p className="center contact-detail bold">Ready to elevate your space with MHYN's exquisite creations? Contact us today to explore our product range, discuss custom designs, or inquire about business collaborations.</p>
      <span>Get in Touch with MHYN</span>
      <address>
        <h3>MHYN India Office:</h3>
        <p>Email Me: <a href="hamzamhyn@ymail.com">hamzamhyn@ymail.com</a> </p>
        <p>Call me: <a href="tel:+5551995911777">+5551995911777</a></p>
        <p>Office address: Mumbai,
          India</p>
      </address>
      <address>
        <h3>MHYN Brazil Office:</h3>
        <p>Email Me: <a href="hamzamhyn@ymail.com">hamzamhyn@ymail.com</a> </p>
        <p>Call me: <a href="tel:+5551995911777">+5551995911777</a></p>
        <p> Office address:Rio grande só Su,
         Brazil</p>
      </address>
      <address>
        <h3>MHYN Dubai Office</h3>
        <p>Email Me: <a href="hamzamhyn@ymail.com">hamzamhyn@ymail.com</a> </p>
        <p>Call me: <a href="tel:+5551995911777">+5551995911777</a></p>
        <p>Office address: NH 564, Jaipur
          India</p>
      </address>
    </div>
  </section>
   )
}
export default Contact
import React from "react";
import AbtImg from "./images/abIMG.jpeg"
import AOS from 'aos';
import 'aos/dist/aos.css';
import "../../src/index.css"
import { useEffect } from "react";
const About = () => {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);
  return (
    <>
      <section className="promo">
        <div className="content"
          data-aos="fade-up"
          data-aos-offset="50"
        >
          {/* <h2>We bring your <span className="bold">ideas</span> to life<span className="orange">.</span></h2> */}
          <h2>ABOUT </h2>
          <h3>Embracing Tradition, Inspiring Innovation</h3>
          <h6>MHYN is more than a brand - it's a legacy of passion, craftsmanship, and innovation. Founded in 1935,<br/>  our journey began with a vision to create timeless cast iron and aluminum products that redefine elegance.</h6>
        </div>

        <section id="about">
          <div className="content">

            <div className="about-container">
              <div className="about-container-left"
                data-aos="fade-up-right"
                data-aos-duration="1000"
              >
                <p><br/>Led by <bold><em>Ebrahim Mhyn,</em> </bold> our brand continues to evolve, blending traditional Indian artistry with global aesthetics. Our commitment to quality, durability, and anti-corrosion technology sets us apart in the industry, earning us recognition from clients worldwide.<br/><br/></p>
             
                <p>
                From humble beginnings in India to global acclaim, MHYN's story is one of resilience, creativity, and dedication to excellence. Join us in celebrating craftsmanship that transcends borders and inspires generations.</p>
              </div>
              <div className="about-container-right"
                data-aos="fade-up-left"
                data-aos-duration="1500"
              >
                <img src={AbtImg} alt="your name, headshot"></img>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  )
}
export default About